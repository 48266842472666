<template>
  <v-card :color="tableColor" class="teros-elevation pb-4">
    <v-card-text>
      <h2 class="font-weight-bold">Faturamento de Produtos Incentivados</h2>
      <div class="d-flex">
        <monthly-filter
          style="max-width: 270px;"
          v-model="competencia"
          @change="doLoad()"
        ></monthly-filter>
        <v-spacer></v-spacer>
        <async-export-button
          competencia
          type="calcProdutos"
        ></async-export-button>
      </div>
    </v-card-text>
    <table class="sticky-header-table" style="width:100%">
      <thead>
        <tr>
          <th class="sticky">Produto</th>
          <th
            class="text-right sticky"
            v-for="(col, idx) in colunas"
            :key="'th-'+idx"
          >{{col.name}}</th>
          <th class="text-right sticky">Total</th>
        </tr>
      </thead>
      <tbody v-for="(tipo, i) in tipos" :key="'t'+i">
        <tr :key="i">
          <td
            :colspan="colunas.length + 2"
            class="text-center font-weight-bold"
            style="border-top: thin solid rgba(0, 0, 0, 0.12);"
          >{{tipo.nome}}</td>
        </tr>
        <item-row
          :item="tabela['fat_bruto_' + tipo.id]"
          itemClass="font-weight-medium"
          valueClass="font-weight-medium"
        ></item-row>

        <item-row :item="tabela['deducao_ipi_' + tipo.id]"></item-row>
        <item-row :item="tabela['deducao_piscofins_' + tipo.id]"></item-row>
        <item-row :item="tabela['deducao_icms_' + tipo.id]"></item-row>
        <item-row :item="tabela['deducao_icmsst_' + tipo.id]"></item-row>
        <item-row :item="tabela['deducao_devolucoes_' + tipo.id]"></item-row>

        <item-row :item="tabela['devolucao_ipi_' + tipo.id]" itemClass="error--text pl-4"></item-row>
        <item-row :item="tabela['devolucao_piscofins_' + tipo.id]" itemClass="error--text pl-4"></item-row>
        <item-row :item="tabela['devolucao_icms_' + tipo.id]" itemClass="error--text pl-4"></item-row>
        <item-row :item="tabela['devolucao_icmsst_' + tipo.id]" itemClass="error--text pl-4"></item-row>
        <item-row :item="tabela['devolucao_liquida_' + tipo.id]" itemClass="error--text pl-4"></item-row>

        <item-row :item="tabela['deducao_totais_' + tipo.id]" itemClass="font-weight-medium"></item-row>

        <item-row
          :item="tabela['fat_liquido_' + tipo.id]"
          itemClass="font-weight-medium"
          valueClass="font-weight-medium"
        ></item-row>
        <item-row
          :item="tabela['investimento_min_ped_' + tipo.id]"
          itemClass="font-weight-medium"
          valueClass="font-weight-medium"
        ></item-row>
        <item-row
          :item="tabela['investimento_adicional_' + tipo.id]"
          itemClass="font-weight-medium"
          valueClass="font-weight-medium"
        ></item-row>

        <item-row :item="tabela['ipi_auferido_' + tipo.id]" valueClass="font-weight-medium"></item-row>
        <item-row :item="tabela['icms_auferido_' + tipo.id]" valueClass="font-weight-medium"></item-row>
        <item-row :item="tabela['qtde_vendida_' + tipo.id]" :isNumber="true"></item-row>
      </tbody>
      <tbody>
        <item-row
          :item="tabela['qtde_vendida_total']"
          :isNumber="true"
          itemClass="font-weight-medium"
          valueClass="font-weight-medium"
          style="border-top: thin solid rgba(0, 0, 0, 0.12);"
        ></item-row>
      </tbody>
    </table>
  </v-card>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  components: {
    "async-export-button": () => import("@/components/async-export-button.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "item-row": () => import("./calculo.ped.itemrow.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    resource: function () {
      return this.apiResource(
        `/v1/faturamento/produtosincentivadoscalc/${this.clientId}`
      );
    },
    resourceProduto: function () {
      return this.apiResource(
        `/v1/faturamento/produtos/${this.clientId}`
      );
    },
    competenciaFilter: function () {
      var mIni = moment(this.competencia[0]);
      var mFim = moment(this.competencia[1]);
      var diff = mFim.diff(mIni, "months");
      if (diff < 0) {
        mIni = moment(this.competencia[1]);
        mFim = moment(this.competencia[0]);
      }
      return { ini: mIni.format("YYYY-MM"), fim: mFim.format("YYYY-MM") };
    },
    filterQuery: function () {
      return `competenciaIni=${this.competenciaFilter.ini}&competenciaFim=${this.competenciaFilter.fim}`;
    },
  },
  methods: {
    doLoad: function () {
      this.resourceProduto.get().then((response) => {
        _.forEach(response, (p) => {
          const { abreviacao, incentivo } = p;
          this.produtos[p.id] = abreviacao && incentivo ? `${abreviacao} — ${incentivo}` : abreviacao;
        });
        this.resource.get({ query: this.filterQuery }).then((response) => {
          this.parseCalculo(response);
        });
      });
    },
    parseCalculo: function (result) {
      // Zerar valores anteriores, caso existam
      let colunas = [];
      _.forEach(this.tabela, (row) => {
        Vue.set(row, 'produtos', {});
        Vue.set(row, 'total', 0);
      });

      // Recriar Colunas e produtos para exibição na tabela
      const uniqResults = _.uniqBy(result, "produtoId");
      uniqResults.forEach((value) => {
        colunas.push({
          name: this.produtos[value.produtoId],
        });
        _.forEach(this.tabela, (row) => {
          Vue.set(row.produtos, `prod-${value.produtoId}`, 0);
          row.total = 0;
        });
      });

      this.colunas = colunas;

      _.forEach(result, (value) => {
        _.forEach(
          Object.keys(value).filter((key) => {
            return ![
              "id",
              "produtoId",
              "empresaId",
              "competencia-old",
              "competencia",
              "competencia_converted",
              "tipo",
              "calculo",
              "resumo",
              "criado_em",
              "modificado_em",
            ].includes(key);
          }),
          (key) => {
            let keyProduto = `prod-${value.produtoId}`;
            let row = this.tabela[`${key}_${value.tipo}`] || { produtos: {}, total: 0 };
            let produtos = row.produtos;

            produtos[keyProduto] = produtos[keyProduto] * 1 + value[key] * 1;
            row.total = row.total * 1 + value[key] * 1;

            if (key == "qtde_vendida") {
              let rowQtd = this.tabela["qtde_vendida_total"];
              rowQtd.produtos[keyProduto] =
                rowQtd.produtos[keyProduto] * 1 + value[key] * 1;              
              rowQtd.total = rowQtd.total * 1 + value[key] * 1;
            }
          }
        );
      });
    },
  },
  data: function () {
    return {
      competencia: [],
      colunas: {},
      produtos: {},
      tabela: {
        fat_bruto_1: {
          item_calculo: "Faturamento Bruto",
          produtos: {},
          total: 0,
        },
        deducao_ipi_1: {
          item_calculo: "Dedução IPI",
          produtos: {},
          total: 0,
        },
        deducao_piscofins_1: {
          item_calculo: "Dedução Pis/Cofins",
          produtos: {},
          total: 0,
        },
        deducao_icms_1: {
          item_calculo: "Dedução ICMS",
          produtos: {},
          total: 0,
        },
        deducao_icmsst_1: {
          item_calculo: "Dedução ICMS ST",
          produtos: {},
          total: 0,
        },
        deducao_devolucoes_1: {
          item_calculo: "Dedução Devoluções",
          produtos: {},
          total: 0,
        },
        devolucao_ipi_1: {
          item_calculo: "(-) Devolução IPI",
          produtos: {},
          total: 0,
        },
        devolucao_piscofins_1: {
          item_calculo: "(-) Devolução Pis/Cofins",
          produtos: {},
          total: 0,
        },
        devolucao_icms_1: {
          item_calculo: "(-) Devolução ICMS",
          produtos: {},
          total: 0,
        },
        devolucao_icmsst_1: {
          item_calculo: "(-) Devolução ICMS ST",
          produtos: {},
          total: 0,
        },
        devolucao_liquida_1: {
          item_calculo: "(-) Devoluções Líquidas",
          produtos: {},
          total: 0,
        },
        deducao_totais_1: {
          item_calculo: "Deduções Totais",
          produtos: {},
          total: 0,
        },
        fat_liquido_1: {
          item_calculo: "Faturamento Líquido",
          produtos: {},
          total: 0,
        },
        investimento_min_ped_1: {
          item_calculo: "Investimento Mínimo em P&D (4%)",
          produtos: {},
          total: 0,
        },
        investimento_adicional_1: {
          item_calculo: "Investimento Adicional em P&D",
          produtos: {},
          total: 0,
        },
        ipi_auferido_1: {
          item_calculo: "IPI Auferido",
          produtos: {},
          total: 0,
        },
        icms_auferido_1: {
          item_calculo: "ICMS Auferido",
          produtos: {},
          total: 0,
        },
        qtde_vendida_1: {
          item_calculo: "Qtde Vendida",
          produtos: {},
          total: 0,
        },
        fat_bruto_2: {
          item_calculo: "Faturamento Bruto",
          produtos: {},
          total: 0,
        },
        deducao_ipi_2: {
          item_calculo: "Dedução IPI",
          produtos: {},
          total: 0,
        },
        deducao_piscofins_2: {
          item_calculo: "Dedução Pis/Cofins",
          produtos: {},
          total: 0,
        },
        deducao_icms_2: {
          item_calculo: "Dedução ICMS",
          produtos: {},
          total: 0,
        },
        deducao_icmsst_2: {
          item_calculo: "Dedução ICMS ST",
          produtos: {},
          total: 0,
        },
        deducao_devolucoes_2: {
          item_calculo: "Dedução Devolções",
          produtos: {},
          total: 0,
        },
        devolucao_ipi_2: {
          item_calculo: "(-) Devolução IPI",
          produtos: {},
          total: 0,
        },
        devolucao_piscofins_2: {
          item_calculo: "(-) Devolução Pis/Cofins",
          produtos: {},
          total: 0,
        },
        devolucao_icms_2: {
          item_calculo: "(-) Devolução ICMS",
          produtos: {},
          total: 0,
        },
        devolucao_icmsst_2: {
          item_calculo: "(-) Devolução ICMS ST",
          produtos: {},
          total: 0,
        },
        devolucao_liquida_2: {
          item_calculo: "(-) Devoluções Líquidas",
          produtos: {},
          total: 0,
        },
        deducao_totais_2: {
          item_calculo: "Deduções Totais",
          produtos: {},
          total: 0,
        },
        fat_liquido_2: {
          item_calculo: "Faturamento Líquido",
          produtos: {},
          total: 0,
        },
        investimento_min_ped_2: {
          item_calculo: "Investimento Min. P&D",
          produtos: {},
          total: 0,
        },
        investimento_adicional_2: {
          item_calculo: "Investimento Adicional",
          produtos: {},
          total: 0,
        },
        ipi_auferido_2: {
          item_calculo: "IPI Auferido",
          produtos: {},
          total: 0,
        },
        icms_auferido_2: {
          item_calculo: "ICMS Auferido",
          produtos: {},
          total: 0,
        },
        qtde_vendida_2: {
          item_calculo: "Qtde Vendida",
          produtos: {},
          total: 0,
        },
        fat_bruto_3: {
          item_calculo: "Faturamento Bruto",
          produtos: {},
          total: 0,
        },
        deducao_ipi_3: {
          item_calculo: "Dedução IPI",
          produtos: {},
          total: 0,
        },
        deducao_piscofins_3: {
          item_calculo: "Dedução Pis/Cofins",
          produtos: {},
          total: 0,
        },
        deducao_icms_3: {
          item_calculo: "Dedução ICMS",
          produtos: {},
          total: 0,
        },
        deducao_icmsst_3: {
          item_calculo: "Dedução ICMS ST",
          produtos: {},
          total: 0,
        },
        deducao_devolucoes_3: {
          item_calculo: "Dedução Devolções",
          produtos: {},
          total: 0,
        },
        devolucao_ipi_3: {
          item_calculo: "(-) Devolução IPI",
          produtos: {},
          total: 0,
        },
        devolucao_piscofins_3: {
          item_calculo: "(-) Devolução Pis/Cofins",
          produtos: {},
          total: 0,
        },
        devolucao_icms_3: {
          item_calculo: "(-) Devolução ICMS",
          produtos: {},
          total: 0,
        },
        devolucao_icmsst_3: {
          item_calculo: "(-) Devolução ICMS ST",
          produtos: {},
          total: 0,
        },
        devolucao_liquida_3: {
          item_calculo: "(-) Devoluções Líquidas",
          produtos: {},
          total: 0,
        },
        deducao_totais_3: {
          item_calculo: "Deduções Totais",
          produtos: {},
          total: 0,
        },
        fat_liquido_3: {
          item_calculo: "Faturamento Líquido",
          produtos: {},
          total: 0,
        },
        investimento_min_ped_3: {
          item_calculo: "Investimento Min. P&D",
          produtos: {},
          total: 0,
        },
        investimento_adicional_3: {
          item_calculo: "Investimento Adicional",
          produtos: {},
          total: 0,
        },
        ipi_auferido_3: {
          item_calculo: "IPI Auferido",
          produtos: {},
          total: 0,
        },
        icms_auferido_3: {
          item_calculo: "ICMS Auferido",
          produtos: {},
          total: 0,
        },
        qtde_vendida_3: {
          item_calculo: "Qtde Vendida",
          produtos: {},
          total: 0,
        },
        qtde_vendida_total: {
          item_calculo: "Qtde Vendida Total",
          produtos: {},
          total: 0,
        },
      },
      tipos: [
        {
          id: 1,
          nome: "Mercado Interno",
        },
        {
          id: 2,
          nome: "Zona Franca",
        },
        {
          id: 3,
          nome: "Exportação",
        },
      ],
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
thead, thead th, tbody tr:first-child {
  background-color: var(--v-table-header-base);

  &:hover {
    background-color: var(--v-table-header-base) !important;
  }
}
</style>